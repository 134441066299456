export const version = "0.14.153";
export const lastUpdate = "23/12/2024 06:00";
import Dexie from "dexie";
class MyDatabase extends Dexie {
    constructor() {
        super("bajawa_pos");
        this.version(1).stores({
            userActions: "++id, Action, createdAt, createdBy, data",
        });
    }
}
export const db = new MyDatabase();
